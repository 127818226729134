import React, { PropsWithChildren } from 'react'
interface ErrorBoundaryProps {
  as?: string
}

export class ErrorBoundary extends React.Component<PropsWithChildren<ErrorBoundaryProps>> {
  static defaultProps = {}
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('componentDidCatch', error, errorInfo)
  }

  render() {
    const { children } = this.props
    return <>{children}</>
  }
};

function withErrorBoundary<P = any>(
  Component: any //React.ComponentType<P>
): React.FC<P & ErrorBoundaryProps> {
  const wrapper = ({
    ...props
  }: ErrorBoundaryProps) => {
    return (
      // <ErrorBoundary>
        <Component {...props as P} />
      // </ErrorBoundary>
    )
  }

  return wrapper
}

export default withErrorBoundary
