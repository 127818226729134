import pxToRem from '@app/ui/utils/pxToRem'
import { excludeProps } from '@app/ui/utils/shouldForwardProp'
import { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export type ButtonProps = ButtonHTMLAttributes<any> & {
  variant?: 'default' | 'primary' | 'disabled',
  disabled?: boolean,
  as?: string,
  fullWidth?: boolean
}

export const ButtonStyle = css<any>`
  padding: 0.75rem 2.5rem;
  border: none;
  border-radius: 6.25rem;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-weight: 700;
  color: 'white';
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgb(242, 86, 12);
  }
  width: ${p => p.fullWidth ? '100%' : 'auto'};
  ${p => p.disabled && `
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
  `}
  ${p => p.theme.button[(p.disabled && 'disabled') || p.variant || 'default']}
`

export const Button = styled.button.withConfig({
  shouldForwardProp: excludeProps(['fullWidth', 'as', 'variant']),
}) <ButtonProps>`
  font-size: ${pxToRem(14)};
  padding: 0.40625rem 1.625rem;
  border: none;
  border-radius: 6.25rem;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-weight: 700;
  color: white;
  line-height: 1.5;
  width: ${p => p.fullWidth ? '100%' : 'auto'};
  transition: background-color 100ms;
  ${p => p.disabled && `
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
  `}
  ${p => p.theme.button[(p.disabled && 'disabled') || p.variant || 'default']}
  &:focus {
    outline: none;
    background-color: rgb(242, 86, 12);
  }
  &:hover {
    background-color: rgb(242, 86, 12);
  }
  & + &, & + a {
    margin-left: 1rem;
  }
`

export default Button
