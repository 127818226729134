import parse, { HTMLReactParserOptions, domToReact, attributesToProps, DOMNode } from 'html-react-parser'
import { Element } from 'domhandler'
import cheerio, { AnyNode } from 'cheerio'
import render from 'dom-serializer'
import urlParser from 'url-parse'

import ButtonLink from '@app/ui/components/ButtonLink'
import Image from '@app/ui/components/Image'
import TestimonialQuote from '@app/ui/components/TestimonialQuote'
import Table from '@app/ui/components/Table'
import InlineDownload from '@app/ui/components/InlineDownload'
import Video, { VIDEO_TYPES } from '@app/ui/components/Video'
import { cleanDoubleSlashes, getFileExtension, hasDefaultProto, humanFileSize } from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { Property } from 'csstype'

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: ${pxToRem(10)};
`

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const options: HTMLReactParserOptions =
{
  replace: domNode => {
    if (isElement(domNode)) {
      const $ = cheerio.load(domNode.children as AnyNode[])
      const attribsAsProps = attributesToProps(domNode.attribs)
      if (attribsAsProps.class) {
        attribsAsProps.className = attribsAsProps.class
        delete attribsAsProps.class
      }
      const classList = domNode.attribs.class?.split(' ')

      if (domNode.name === 'a') {
        if (classList?.includes('btn')) {
          return (<ButtonLink {...(attribsAsProps as any)}>{domToReact(domNode.children)}</ButtonLink>)
        } else if (domNode.attribs?.href) {
          const target = domNode.attribs?.target
          const opensInNewTab = target && !['_self', '_parent', '_top'].includes(target)
          const isPdf = getFileExtension(domNode.attribs?.href) === 'pdf'
          const isHttp = hasDefaultProto(domNode.attribs?.href)

          return opensInNewTab ? (<a
            {...(attribsAsProps as any)}
            href={cleanDoubleSlashes(domNode.attribs.href)}
            target="_blank"
            rel="noreferrer"
          >
            {domToReact(domNode.children)}
            {!isPdf && isHttp && $('img').length === 0 && <StyledIcon icon={['far', 'external-link']} />}
          </a>)
            :
            (<Link href={cleanDoubleSlashes(domNode.attribs.href)}>{domToReact(domNode.children)}</Link>)
        }
      }
      if (domNode.name === 'blockquote') {
        // @ts-ignore
        return <TestimonialQuote {...attribsAsProps} textChildren={domToReact(domNode.children)} />
      }
      if (domNode.name === 'table') {
        return <Table {...attribsAsProps} tableHTML={render(domNode)} />
      }
      if (domNode.name === 'div' && classList?.includes('downloads')) {
        const title = $('.doc-info').text()
        const href = $('a').attr('href')
        const downloadSize = Number($('.size-doc').text())

        return <InlineDownload
          {...attribsAsProps}
          downloadUrl={href ?? '#'}
          downloadTitle={title}
          downloadFileSize={downloadSize ? `(${humanFileSize(downloadSize)})` : ''}
        />
      }
      if (domNode.name === 'div' && classList?.includes('video-img-wrapper')) {
        const href = $('a').attr('href')
        const classes = $('a').attr('class')?.split(' ')
        const imageSrc = $('img').attr('src')
        const url = urlParser(String(href), true)

        const videoId = url.pathname.split('/').pop()
        const videoType = url.host.includes('youtube.com') ? VIDEO_TYPES.YOUTUBE : VIDEO_TYPES.VIMEO
        const coverImageUrl = imageSrc
        const displayInCompactWindow = classes?.includes('compact-view')
        const autoPlay = url.query.autoplay === '1'

        return <Video
          videoId={String(videoId)}
          videoType={videoType}
          coverImageUrl={coverImageUrl}
          displayInCompactWindow={displayInCompactWindow}
          autoPlay={autoPlay}
        />
      }
      if (domNode.name === 'figure') {
        const $img = $('img')
        const $caption = $('figcaption')

        return $img.length !== 0 ?
        (<Image {...$img.attr()} src={cleanDoubleSlashes($img.attr('src') as string)} caption={$caption.text()} alt={$caption.text()} />) : null
      }
      if (domNode.name === 'img') {
        let style

        const alignmentParserRegex = /align(\w+)?(\s*>\s*)?(#\w+)?\s*(\.\w+)?\s*/

        let alignment = 'initial'

        if (domNode.attribs?.class) {
          const matches = domNode.attribs?.class.match(alignmentParserRegex)

          if (matches && matches.length > 1) {
            switch(matches[1]) {
              case 'left':
                alignment = 'left'
                break
              case 'center':
                alignment = 'center'
                break
              case 'right':
                alignment = 'right'
                break
              default:
                break
            }
          }
        }

        return (<div style={{ textAlign: (alignment ?? 'initial') as Property.TextAlign }}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image {...attribsAsProps} src={cleanDoubleSlashes(domNode.attribs?.src)} style={style} />
        </div>)
      }
    }
  }
}

export const attachEmbeddedScripts = function (html: string) {
  // First, extract all <script>...</script> strings (e.g. <script src='https://embed.typeform.com/next/embed.js'></script>)

  const re = /<script.*script>/g
  const scriptTagsStrings = html.match(re)

  if(!scriptTagsStrings) {
    return;
  }

  // Next, strip them down to just the URL (e.g. 'https://embed.typeform.com/next/embed.js')

  let scriptUrls : string[] = []
  for(let i = 0; i < scriptTagsStrings.length; i++) {
    const re = /src=".*"/g
    const scriptTagUrl = html.match(re)

    if(!scriptTagUrl || scriptTagUrl.length === 0) {
      continue
    }

    scriptUrls.push(scriptTagUrl[0].slice(5, scriptTagUrl[0].length - 1)) // we know the lengths to slice because they are hard-coded in the regex
  }

  // Then, append each script to the document.head

  for(let i = 0; i < scriptUrls.length; i++) {
    const scriptUrl = scriptUrls[i]

    const regex = /[^a-z]/g;
    // @ts-ignore: assume we are in a position to use replaceAll()
    let strippedUrl = scriptUrl.replaceAll(regex, '')

    if(strippedUrl.indexOf('.') != -1) {
      return  // This indicates that we are in a context where replaceAll() doesn't work (IE 11). Give up trying to run the scripts.
    }

    if(strippedUrl === '') {
      continue // scriptUrl didn't contain any letters a-z, let's not run it.
    }

    let scriptId = `${strippedUrl}-script`  // This is the #id of the element we will generate for the script.

    if(document.getElementById(scriptId)) {
      continue  // We must have already set this script up in document.
    }

    // Get the script to run  (https://github.com/remarkablemark/html-react-parser/issues/98)

    const scriptElement = document.createElement('script')
    scriptElement.id = scriptId
    scriptElement.setAttribute('src', scriptUrl)
    document.head.appendChild(scriptElement)
  }
}

export const parseHTML = function (html: string) {
  if (!html) {
    html = ''
  }

  attachEmbeddedScripts(html);

  return parse(html, options);
}
