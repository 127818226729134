import { parseHTML } from '@app/lib/parseHTML'
import pxToRem from '@app/ui/utils/pxToRem'
import { FC } from 'react'
import styled from 'styled-components'
import { OneColumnRowProps } from './types'
import { StyledButton } from '../ButtonLink'
import { media } from 'styled-bootstrap-grid'

const Container = styled.div<{
  backgroundColor?: string,
  constrained?: boolean,
}>`

& p {
  line-height: ${pxToRem(20)};
  ${media.desktop`
  line-height: ${pxToRem(24)};
  `}
}
  ${props => props.constrained && `
    padding-left: ${pxToRem(16)};
    padding-right: ${pxToRem(16)};
    @media screen and (min-width: 992px) {
      padding-left: ${pxToRem(34)};
      padding-right: ${pxToRem(34)};
    }
  `}
  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor};
  `}
  .downloads:first-child {
    margin-top: 0;
  }

  ${StyledButton} {
    margin-top: ${pxToRem(10)};
    text-decoration: none;
  }

  @media screen and (max-width: 992px) {
    h3, p:not(:last-child) {
      margin-bottom: ${pxToRem(10)};
    }

    li {
      font-size: ${pxToRem(14)};
    }
  }

  width: 100%;

`

export const OneColumnRow: FC<OneColumnRowProps & { className?: string, style?: React.CSSProperties }> = ({
  html,
  backgroundColor,
  constrained,
  children,
  contentAnchorNavigation,
  className,
  style,
}) => {
  let content = null

  if (html) {
    content = parseHTML(html.replace(/<p>&nbsp;<\/p>/g, ''))
  }
  if (!content && !children) {
    return null
  }

  return <Container
    backgroundColor={backgroundColor}
    constrained={constrained}
    className={className}
    style={style}
  >
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
    }{contentAnchorNavigation?.enable && <a id={`${contentAnchorNavigation.linkTitle}`} />}
    {content ?? children}
  </Container>
}

export default OneColumnRow
