import React from 'react'
import styled from 'styled-components'
import withErrorBoundary from '@ui/shared/ErrorBoundary'
import { excludeProps } from '@ui/utils/shouldForwardProp'
import pxToRem from '@app/ui/utils/pxToRem'

export type ImageProps = {
  width?: number
  height?: number
  src: string
  radius?: string
  caption?: string
  noGutter?: boolean
  alt?: string
  className?: string
}

const Container = styled('figure').withConfig({
  shouldForwardProp: excludeProps(['radius', 'height']),
}) <{
  radius?: string
  noGutter?: boolean
  height?: number
}>`
  border-radius: ${p => p.radius};
  position: relative;
  margin-bottom: ${p => (p.noGutter && '0') || 'initial'};
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

const Img = styled('img').withConfig({
  shouldForwardProp: excludeProps(['radius']),
}) <{
  radius?: string
  layout?: 'fill'
}>`
  position: relative;
  border-radius: ${p => p.radius};
  object-fit: cover;
`

const Caption = styled('figcaption').withConfig({
  shouldForwardProp: excludeProps(['radius']),
}) <{
  radius?: string
}>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  bottom: 0;
  border-bottom-left-radius: ${p => p.radius};
  border-bottom-right-radius: ${p => p.radius};
  background-color: rgba(1, 47, 87, 0.65);
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  max-height: ${pxToRem(55)};
`

const CaptionText = styled.span`
  margin-left: 2.82%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export class Image extends React.Component<ImageProps> {
  img: HTMLImageElement | null = null
  static defaultProps = {
    radius: pxToRem(10),
  }
  state = {
    loaded: false,
  }
  componentDidMount() {
    this.loadImage()
    window.addEventListener('scroll', this.loadImage)
  }

  componentWillUnmount() {
    this.removeEvent()
  }

  removeEvent() {
    window.removeEventListener('scroll', this.loadImage)
  }

  elementInViewport(el: HTMLImageElement) {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    )
  }

  loadImage = () => {
    if (this.img && this.elementInViewport(this.img)) {
      this.img.onload = this.onLoaded
      this.img.src = this.props.src
    }
  }

  onLoaded = () => {
    this.removeEvent()
    this.setState({ loaded: true })
  }
  render() {
    const { width, height, radius, src, caption, alt, className, ...rest } = this.props

    return (
      <>
        <Container radius={radius} height={height}>
          <Img
            src={src}
            title={caption || alt}
            radius={radius}
            alt={caption || alt}
            {...rest}
          />
          {caption && (
            <Caption radius={radius}>
              <CaptionText>{caption}</CaptionText>
            </Caption>
          )}
        </Container>
      </>
    )
  }
}

export default withErrorBoundary(Image)
