import React from 'react'
import styled from 'styled-components'
import VimeoVideo, { VimeoProps } from '@u-wave/react-vimeo'
import pxToRem from '@app/ui/utils/pxToRem'

export type VimeoVideoProps = VimeoProps & {}

const Video = styled(VimeoVideo)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  border-radius: 7px;
  margin-bottom: ${pxToRem(24)};

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
`

export const Vimeo = ({ video, autoplay, ...rest }: VimeoVideoProps) => {
  return (
    <Video
      video={video}
      showTitle={false}
      autoplay={autoplay}
      {...rest} 
    />
  )
}

export default Vimeo
