import React, { FC, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { media, Row, Col } from 'styled-bootstrap-grid'
import withErrorBoundary from '@ui/shared/ErrorBoundary'
import FontIcon from '@ui/components/FontIcon'
import OneColumnRow from '@ui/components/OneColumnRow'
import { AccordionProps } from '.'
import pxToRem from '@app/ui/utils/pxToRem'

export type AccordionComponentProps = {
  row: AccordionProps
  activeIndex: number
  index: number
  setActiveIndex: (index: number) => void
  backgroundColor?: string
}

const Card = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  & + & {
    margin-top: ${pxToRem(15)};
  }
  strong {
    font-weight: 800;
  }
  a {
    text-decoration: underline;
  }
`

const CardHeader = styled('div') <{
  isActive: boolean
}>`
  background: white 0% 0% no-repeat padding-box;
  border-radius: ${pxToRem(7)};
  cursor: pointer;
  display: table;
  border-bottom: 1px solid #E2ECF2;
  border-bottom-left-radius: ${props => props.isActive ? 0 : pxToRem(7)};
  border-bottom-right-radius: ${props => props.isActive ? 0 : pxToRem(7)};
  transition: 0.4s ease border-bottom-left-radius, 0.4s ease border-bottom-right-radius;

  span {
    float: right;
  }

  ${props =>
    (props.isActive &&
      `
    span {
      color: #ff6820;
    }
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    `) ||
    `
    span {
      color: #00afd9;
    }
    &:hover span {
      color: #ff6820;
    }
  `}
  ${media.xs`
    padding: ${pxToRem(11)} ${pxToRem(15)} ${pxToRem(11)} ${pxToRem(15)};
  `}
  ${media.sm`
    padding: ${pxToRem(12)} ${pxToRem(21)} ${pxToRem(12)} ${pxToRem(15)};
  `}
`

const CardBody = styled.div`
  transition: max-height 0.5s, opacity 1s;
  opacity: 0;
  maxHeight: 0;
`

const CardBodyContent = styled.div<{
  backgroundColor?: string
}>`
  background-color: ${props => props.backgroundColor || 'white'};
  flex: 1 1 auto;
  min-height: ${pxToRem(1)};
  padding-top: ${pxToRem(15)};
  padding-bottom: ${pxToRem(20)};
  padding-left: ${pxToRem(15)};
  padding-right: ${pxToRem(15)};
  border-top: 1px solid #E2ECF2;
  border-bottom-left-radius: ${pxToRem(7)};
  border-bottom-right-radius: ${pxToRem(7)};

  ${media.desktop`
    padding: ${pxToRem(18)} ${pxToRem(32)};
  `}
`

const AccordionHeading = styled.div <{
  isActive: boolean
}>`
  text-align: left;
  color: ${props => props.theme.heading.color};
  padding-right: ${pxToRem(10)};

  ${media.xs`
    display: block;
    font-size: ${pxToRem(15)};
    line-height: 1.75;
  `}
  ${media.sm`
    font-size: ${pxToRem(16)};
  `}

  ${media.tablet`
    padding-right: ${pxToRem(20)};
  `}
`

const CollapseButton = styled.div`
  display: table-cell;
  vertical-align: middle;
`

export const AccordionComponent: FC<AccordionComponentProps> = ({
  row,
  activeIndex,
  index,
  setActiveIndex,
  backgroundColor,
}) => {
  const numColumns = Number(row.columns)

  const [isAnimating, setIsAnimating] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const collapseRef: any = useRef(null)
  const contentRef: any = useRef(null)

  const rowSpan = 12 / Math.max(1, numColumns)

  const handleClick = () => {
    setActiveIndex(isActive ? -1 : index)
    if (!isAnimating) {
      setIsAnimating(true)
    }
  }

  const handleTransition = () => {
    if (isActive) {
      collapseRef.current.style.maxHeight = ''
    }
    setIsAnimating(false)
  }

  useEffect(() => {
    const isActive = activeIndex === index
    setIsActive(isActive)

    const { height } = contentRef.current.getBoundingClientRect()
    collapseRef.current.style.maxHeight = `${height}px`
    collapseRef.current.style.opacity = 1

    if (!isActive) {
      requestAnimationFrame(() => {
        if (collapseRef.current) {
          collapseRef.current.style.maxHeight = 0
          collapseRef.current.style.opacity = 0
        }
      })
    }
  }, [index, activeIndex])

  return (
    <Card>
      <CardHeader
        isActive={isActive}
        onClick={handleClick}
      >
        <AccordionHeading dangerouslySetInnerHTML={{ __html: row.title }} isActive={isActive} />
        <CollapseButton>
          <span>
            <FontIcon
              icon={{
                prefix: 'fas',
                iconName: isActive ? 'minus' : 'plus',
              }}
            />
          </span>
        </CollapseButton>
      </CardHeader>
      <CardBody
        ref={collapseRef}
        onTransitionEnd={handleTransition}
      >
        <CardBodyContent backgroundColor={backgroundColor} ref={contentRef}>
          <Row>
            {row.column1 && <Col col xs={12} sm={rowSpan}>
              <OneColumnRow html={row.column1} />
            </Col>}
            {(numColumns === 2) && row.column2 && <Col col xs={12} sm={rowSpan}>
              <OneColumnRow html={row.column2} />
            </Col>}
          </Row>
        </CardBodyContent>
      </CardBody>
    </Card>
  )
}

export default withErrorBoundary(AccordionComponent)
