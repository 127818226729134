import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import withErrorBoundary from '@ui/shared/ErrorBoundary'

export type TableProps = {
  tableHTML: string,
}

const Container = styled.div`
  table {
    background-color: ${props => props.theme.table.backgroundColor};
    border-radius: 0px 6px 6px 6px;
    width: 100%;
    border-collapse: collapse;
    thead {
      th {
        vertical-align: bottom;
        border-bottom: 1px solid #c9c9c9;
      }
    }

    th {
      color: ${props => props.theme.table.headerColor};
      text-align: left;
      ${media.xs`
        padding: 0.75rem;
        font-size: 0.6875rem;
        line-height: 1.818181818;
        letter-spacing: -0.03px;
      `}
      ${media.sm`
        padding: 1.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.875;
        letter-spacing: -0.06px;
      `}
    }

    td {
      vertical-align: top;
      color: ${props => props.theme.table.color};
      border: none;
      ${media.xs`
        padding: 0.75rem;
        font-size: 0.6875rem;
        line-height: 1.818181818;
        letter-spacing: -0.03px;
      `}
      ${media.sm`
        padding: 1rem;
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        line-height: 1.75em;
        letter-spacing: -0.05px;
      `}
    }

    th:first-child, td: first-child {
      ${media.xs`
        padding-left: 0.9375rem;
      `}
      ${media.sm`
        padding-left: 2.25rem;
      `}
    }

    th:last-child, td: last-child {
      ${media.xs`
        padding-right: 2.25rem;
      `}
      ${media.sm`
        padding-right: 4.25rem;
      `}
    }

    tr:first-child td {
      ${media.xs`
        padding-top: 1.3125rem;
      `}
      ${media.sm`
        padding-top: 2.5rem;
      `}
    }
    tr:last-child td {
      ${media.xs`
        padding-bottom: 1.6875rem;
      `}
      ${media.sm`
        padding-bottom: 2.5rem;
      `}
    }
  }
`

export const Table = ({ tableHTML }: TableProps) => {
  return <Container dangerouslySetInnerHTML={{ __html: tableHTML }} />
}

export default withErrorBoundary(Table)
