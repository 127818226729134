import React, { FC } from 'react'
import styled from 'styled-components'
import { TestimonialIcon } from '@app/assets/icons'
import Image from 'next/image'
import pxToRem from '@app/ui/utils/pxToRem'
import { media } from 'styled-bootstrap-grid'

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: ${pxToRem(7)};
  position: relative;
  padding: ${pxToRem(60)} ${pxToRem(15)} ${pxToRem(50)} ${pxToRem(15)};
  flex-direction: column;
  margin-top: ${pxToRem(50)};
  margin-bottom: ${pxToRem(23.2)};

  ${media.desktop`
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: ${pxToRem(20)};
  `}

  &::after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-left: ${pxToRem(11)} solid transparent;
    border-right: ${pxToRem(11)} solid transparent;
    border-top: ${pxToRem(14)} solid #f3f3f3;
    bottom: -${pxToRem(14)};
    left: ${pxToRem(33)};
  }
  ${media.desktop`

    padding: ${pxToRem(39)} ${pxToRem(35)} ${pxToRem(39)};
    margin-bottom: ${pxToRem(30)};
    &::after {
      display: block;
      border-left: ${pxToRem(15)} solid transparent;
      border-right: ${pxToRem(15)} solid transparent;
      border-top: ${pxToRem(30)} solid #f3f3f3;
      bottom: -${pxToRem(30)};
      left: ${pxToRem(65)};
    }
  `}
`

const Icon = styled.div`
  background-color: ${p => p.theme.colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 4px solid #E2F1F8;
  width: ${pxToRem(95)};
  height: ${pxToRem(95)};
  font-size: ${(props: any) => props.theme.fontSizes.large2x};
  top: ${pxToRem(-47)};

  ${media.tablet`
    width: ${pxToRem(80)};
    height: ${pxToRem(80)};
    aspect-ratio: 1/1;
`}

  ${media.desktop`
    position: relative;
    top: unset;
  `}
`

const Message = styled.div`
  color: ${props => props.theme.colors.primary};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  strong {
    font-weight: 800;
  }
  ${media.desktop`
    margin-left: ${pxToRem(34)};
    font-size: ${(props: any) => props.theme.fontSizes.large2x};
    max-width: 70%;
  `}
`

export interface TestimonialQuoteProps {
  textChildren: string[]
}

const TestimonialQuote: FC<TestimonialQuoteProps> = ({ textChildren, ...rest }) => {

  return (
    <Container {...rest}>
      <Icon>
        <Image src={TestimonialIcon} layout="fill" alt="" />
      </Icon>
      <Message>{textChildren}</Message>
    </Container>
  )
}

export default TestimonialQuote
