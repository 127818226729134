import styled from 'styled-components'
import { Button } from '../Button'

export const CircleButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 5px;
  right: 0;
`

export default CircleButton