import React from 'react'
import styled from 'styled-components'
import DownloadIcon from '@ui/components/icons/DownloadIcon'
import { media } from 'styled-bootstrap-grid'
import pxToRem from '@app/ui/utils/pxToRem'

export type InlineDownloadProps = React.HTMLAttributes<HTMLDivElement> & {
  downloadTitle?: string
  downloadUrl: string
  downloadFileSize?: string
}

const DownloadTitle = styled.div`
  font-size: ${p => p.theme.fontSizes.normal1};
  font-weight: 900;
  color: #012f57;
  display: block;
  margin-bottom: 0.75rem;
  ${media.desktop`
    font-size: ${(p: any) => p.theme.fontSizes.medium2x};
  `}
`
const DownloadLink = styled('a')`
`

const DownloadIconStyled = styled(DownloadIcon)`
  margin-right: 0.625rem;
  font-size: ${p => p.theme.fontSizes.medium};
`

const Container = styled.div`
  background-color: #ecf3fa;
  width: auto;
  border-radius: 0.4375rem;
  cursor: pointer;
  padding: 1rem 0.9375rem 1.275rem;
  margin: 1.25rem 0;
  width: 100%;
  &:hover {
    ${DownloadLink} {
      color: #ff6820;
    }
  }
  ${DownloadLink} {
    color: #00afd9;
    text-decoration: underline;
    svg {
      color: unset;
    }
  }
  ${media.desktop`
    margin-right: ${pxToRem(120)};
    padding: 2.125rem 2.8125rem;
  `}
`

const FileSize = styled.span`
  margin-left: ${pxToRem(15)};
  font-size: 0.95rem;
  font-weight: 500;

  ${media.xl`
    margin-left: ${pxToRem(30)};
  `}
`

const handleDownloadClick = (event: any) => {
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()
}

export const InlineDownload = ({
  downloadUrl,
  downloadTitle,
  downloadFileSize,
  ...rest
}: InlineDownloadProps) => (
  <Container
    {...rest}
    onClick={() => {
      window.location.href = downloadUrl
    }}
  >
    {downloadTitle && <DownloadTitle>{downloadTitle}</DownloadTitle>}
    <DownloadLink
      href={downloadUrl}
      target="_blank"
      onClick={handleDownloadClick}>
      <DownloadIconStyled />
      Download
    </DownloadLink>
    <FileSize>
      {downloadFileSize}
    </FileSize>
  </Container>
)

export default InlineDownload
