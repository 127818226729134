import React, { FC, useState } from 'react'
import styled from 'styled-components'
import withErrorBoundary from '@ui/shared/ErrorBoundary'
import AccordionComponent from './AccordionComponent'
import { AnchorNavProps, HideOnDeviceProps } from '@app/ui/types/fcpprops'

export type AccordionProps = {
  title: string
  columns: string
  column1?: string
  column2?: string
}

export type AccordionSectionProps = HideOnDeviceProps & AnchorNavProps & {
  backgroundColor?: string
  accordion: AccordionProps[]
}

const AccordionContainer = styled.div`
  border-radius: 0.4375rem;
  overflow-anchor: none;
`

export const AccordionSection: FC<AccordionSectionProps> = ({
  backgroundColor,
  accordion,
  contentAnchorNavigation,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1)

  return (
    <AccordionContainer>
      {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
      }{contentAnchorNavigation?.enable && <a id={`${contentAnchorNavigation.linkTitle}`} />}
      {accordion?.map((row, index) => {
        return (
          <AccordionComponent
            backgroundColor={backgroundColor}
            key={index}
            row={row}
            activeIndex={activeIndex}
            index={index}
            setActiveIndex={setActiveIndex}
          ></AccordionComponent>
        )
      })}
    </AccordionContainer>
  )
}

export default withErrorBoundary(AccordionSection)
