// @ts-nocheck
// FIXME: types from @u-wave/react-youtube are outdated with no clear alternative as of 12/09/23
// You are welcome to ~try~ alternative options: https://github.com/microsoft/TypeScript/issues/17042
import React from 'react'
import styled from 'styled-components'
import YoutubeVideo, { YouTubeProps } from '@u-wave/react-youtube'
import pxToRem from '@app/ui/utils/pxToRem'

export type YoutubeVideoProps = YouTubeProps & {}

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: ${pxToRem(24)};

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
`

export const Youtube = ({ video, autoplay, ...rest }: YoutubeVideoProps) => {
  return (
    <Container>
      <YoutubeVideo
        video={video}
        showInfo={false}
        showRelatedVideos={false}
        showCaptions={false}
        annotations={false}
        autoplay={autoplay}
        modestBranding={true}
        {...rest}
      />
    </Container>
  )
}

export default Youtube
