import { excludeProps } from '@app/ui/utils/shouldForwardProp'
import React from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import CircleButton from '../CircleButton'
import PauseIcon from '../icons/PauseIcon'
import PlayIcon from '../icons/PlayIcon'
import Vimeo from './Vimeo'
import Youtube from './Youtube'
import Image from 'next/image'
import pxToRem from '@ui/utils/pxToRem'

export enum VIDEO_TYPES {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
}

enum VIDEO_STATUS {
  PAUSED = 'paused',
  PLAY = 'play',
}

export type VideoProps = {
  videoId: string
  videoType: VIDEO_TYPES
  coverImageUrl?: string
  displayInCompactWindow?: boolean
  autoPlay?: boolean
}
const Container = styled.div`
  position: relative;
  margin: ${pxToRem(20)} 0;
`
const StatusButton = styled(CircleButton).withConfig({
  shouldForwardProp: excludeProps(['hide']),
}) <any>`
  width: 13%;
  height: 22%;
  background: #012f57;
  display: ${p => (p.hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  font-size: ${pxToRem(23)};
  // opacity: 0.8;
  z-index: 3;

  & > svg {
    height: 30%;
  }

  ${media.max.tablet`
    width: ${pxToRem(75)};
    height: ${pxToRem(75)};
  `}
  ${media.max.phone`
    width: ${pxToRem(55)};
    height: ${pxToRem(55)};
  `}
`

const Backdrop = styled.div<{
  isVideoPlaying: boolean
}>`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: all 0.5s ease-in-out;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    ${StatusButton} {
      background-color: rgb(242, 86, 12);
      display: flex !important;
    }
  }
  ${props => props.isVideoPlaying && `
    ${StatusButton} {
      display: none;
    }
  `}
`

const CoverImgContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  div {
    border-radius: ${pxToRem(7)};
  }
`

const StatesButton = ({
  status,
  isVideoPlaying,
  onClick,
}: {
  status: VIDEO_STATUS
  isVideoPlaying: boolean
  onClick?: () => void
}) => !isVideoPlaying ?(
  <Backdrop onClick={onClick} isVideoPlaying={isVideoPlaying}>
    {
      <StatusButton onClick={onClick}>
        {(status === VIDEO_STATUS.PLAY && <PauseIcon />) || <PlayIcon />}
      </StatusButton>
    }
  </Backdrop>
) : null

export class Video extends React.Component<VideoProps> {
  player: any = null
  state: any = {
    status: VIDEO_STATUS.PAUSED,
    isShowLightBox: false,
  }

  handleOnReady = (ref: any) => {
    if (!this.player) this.player = ref.target || ref
  }

  toggleLightBox = () => {
    const { isShowLightBox } = this.state
    if (this.state.status === VIDEO_STATUS.PAUSED) {
      if (this.props.displayInCompactWindow) {
        this.setState({ isShowLightBox: !isShowLightBox })
      }
      this.playVideo()
    } else {
      this.pauseVideo()
    }
  }

  playVideo() {
    this.setState({
      status: VIDEO_STATUS.PLAY
    })
    if (this.props.videoType === VIDEO_TYPES.VIMEO) {
      this.player && this.player.play()
    }
    if (this.props.videoType === VIDEO_TYPES.YOUTUBE) {
      this.player && this.player.playVideo()
    }
  }

  pauseVideo() {
    this.setState({
      status: VIDEO_STATUS.PAUSED
    })
    if (this.props.videoType === VIDEO_TYPES.VIMEO) {
      this.player && this.player.pause()
    }
    if (this.props.videoType === VIDEO_TYPES.YOUTUBE) {
      this.player && this.player.pauseVideo()
    }
  }

  onPlay = () => {
    const { displayInCompactWindow, autoPlay } = this.props
    if (displayInCompactWindow && !this.state.isShowLightBox && !autoPlay) {
      this.setState({ isShowLightBox: true })
    }
    this.setState({ status: VIDEO_STATUS.PLAY })
  }

  onPause = () => {
    this.setState({ status: VIDEO_STATUS.PAUSED })
  }

  isEnableLightBox(): boolean {
    if (this.state.isShowLightBox && this.props.displayInCompactWindow) {
      return true
    }

    return false
  }

  closeLightBox = () => {
    this.setState({
      isShowLightBox: false
    })
  }

  isVideoPlaying() {
    return this.state.status === VIDEO_STATUS.PLAY
  }

  handleVimeoError = (error: any) => {
    if (error && error.name === 'PlaybackError') {
      this.player.loadVideo(this.props.videoId)
    }
  }

  renderVideo() {
    const { videoId, videoType, autoPlay } = this.props
    return videoType === VIDEO_TYPES.YOUTUBE ? (
      <Youtube
        video={videoId}
        autoplay={autoPlay}
        onPlaying={this.onPlay}
        onBuffering={this.onPlay}
        onEnd={this.onPause}
        onPause={this.onPause}
        onReady={this.handleOnReady}
      />
    ) : (
      <Vimeo
        video={videoId}
        autoplay={autoPlay}
        onPlay={this.onPlay}
        onEnd={this.onPause}
        onPause={this.onPause}
        onReady={this.handleOnReady}
        onError={this.handleVimeoError}
      />
    )
  }

  render() {
    const { status } = this.state
    const { coverImageUrl, videoId, videoType } = this.props
    return (
      <Container>
        {
          coverImageUrl && !this.isVideoPlaying() && (
            <CoverImgContainer>
              <Image src={coverImageUrl ? (coverImageUrl) : (videoType == VIDEO_TYPES.YOUTUBE ? (`https://img.youtube.com/vi/${videoId}/default.jpg`) : (''))} layout="fill" alt="" />
            </CoverImgContainer>
          )
        }
          {this.renderVideo()}
        <StatesButton isVideoPlaying={this.isVideoPlaying()} status={status} onClick={this.toggleLightBox} />
      </Container>
    )
  }
}

export default Video
